var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "sleep-window-input", attrs: { "max-width": "400px" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-2" },
        [
          _c(
            "v-layout",
            { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", [
                _c(
                  "h3",
                  { staticClass: "starling-h3" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          large: "",
                          color: "primary",
                          "icon-pack": "mdi"
                        }
                      },
                      [_vm._v("mdi-bed-king-outline")]
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("app.tools.sleep_window.title")) +
                        "\n        "
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", [
                _c(
                  "p",
                  { staticClass: "starling-body font-weight-semi-bold" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "app.tools.sleep_window.fields.average_per_night"
                          )
                        )
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { grow: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "hoursMenu",
                              attrs: {
                                "content-class": "sleep-window-menu",
                                "return-value": _vm.innerValue.desiredHours
                              },
                              on: {
                                "update:returnValue": function($event) {
                                  return _vm.$set(
                                    _vm.innerValue,
                                    "desiredHours",
                                    $event
                                  )
                                },
                                "update:return-value": function($event) {
                                  return _vm.$set(
                                    _vm.innerValue,
                                    "desiredHours",
                                    $event
                                  )
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "sleep-input",
                                            attrs: { round: "", outline: "" }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.innerValue.desiredHours
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(
                                  [
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7",
                                    "8",
                                    "9",
                                    "10",
                                    "11",
                                    "12"
                                  ],
                                  function(item, index) {
                                    return _c(
                                      "v-list-tile",
                                      {
                                        key: index,
                                        class:
                                          item === _vm.innerValue.desiredHours
                                            ? "selected"
                                            : "",
                                        attrs: {
                                          "active-class": "",
                                          value: index
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.hoursMenu.save(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-title",
                                          { staticClass: "text-center" },
                                          [_vm._v(_vm._s(item))]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c(
                          "p",
                          { staticClass: "starling-text centered-text" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("app.tools.sleep_window.fields.hours")
                              )
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "minutesMenu",
                              attrs: {
                                "content-class": "sleep-window-menu",
                                "return-value": _vm.innerValue.desiredMinutes
                              },
                              on: {
                                "update:returnValue": function($event) {
                                  return _vm.$set(
                                    _vm.innerValue,
                                    "desiredMinutes",
                                    $event
                                  )
                                },
                                "update:return-value": function($event) {
                                  return _vm.$set(
                                    _vm.innerValue,
                                    "desiredMinutes",
                                    $event
                                  )
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "sleep-input",
                                            attrs: { round: "", outline: "" }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.innerValue.desiredMinutes
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                _vm._l(["00", "15", "30", "45"], function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      key: index,
                                      class:
                                        item === _vm.innerValue.desiredMinutes
                                          ? "selected"
                                          : "",
                                      attrs: {
                                        "active-class": "",
                                        value: index
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.minutesMenu.save(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticClass: "text-center" },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { grow: "" } }, [
                        _c(
                          "p",
                          { staticClass: "starling-text centered-text" },
                          [_vm._v("min")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "starling-body centered-text text-wrap font-weight-semi-bold"
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.tools.sleep_window.fields.waking_hour_requested"
                        )
                      )
                    )
                  ]
                )
              ]),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "timeDialog",
                      attrs: {
                        "return-value": _vm.innerValue.desiredWakeTime,
                        persistent: "",
                        lazy: "",
                        "full-width": "",
                        width: "310px",
                        "content-class": "radius-15"
                      },
                      on: {
                        "update:returnValue": function($event) {
                          return _vm.$set(
                            _vm.innerValue,
                            "desiredWakeTime",
                            $event
                          )
                        },
                        "update:return-value": function($event) {
                          return _vm.$set(
                            _vm.innerValue,
                            "desiredWakeTime",
                            $event
                          )
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-icon",
                                { staticClass: "sleep-input-icon" },
                                [_vm._v("schedule")]
                              ),
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "sleep-input",
                                    attrs: { round: "", outline: "" }
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.formattedWakeTime) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.wakeTimeDialog,
                        callback: function($$v) {
                          _vm.wakeTimeDialog = $$v
                        },
                        expression: "wakeTimeDialog"
                      }
                    },
                    [
                      _c(
                        "time-picker",
                        {
                          attrs: {
                            "minute-interval": 5,
                            format: _vm.timeFormat
                          },
                          model: {
                            value: _vm.innerValue.desiredWakeTime,
                            callback: function($$v) {
                              _vm.$set(_vm.innerValue, "desiredWakeTime", $$v)
                            },
                            expression: "innerValue.desiredWakeTime"
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { "align-center": "", "justify-end": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c("v-btn", {
                                    attrs: { flat: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.wakeTimeDialog = true
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: { id: "btn-time-picker-save" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.timeDialog.save(
                                            _vm.innerValue.desiredWakeTime
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("common.actions.save")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "text-center" },
                [
                  _c(
                    "primary-button",
                    {
                      attrs: { disabled: _vm.isComputed && !_vm.isDirty },
                      on: { click: _vm.onCalculate }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isComputed
                              ? _vm.$t("common.actions.recalculate")
                              : _vm.$t("common.actions.calculate")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isComputed
            ? [
                _c(
                  "v-layout",
                  { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { grow: "" } }, [
                      _c(
                        "p",
                        { staticClass: "starling-body font-weight-semi-bold" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("app.tools.sleep_window.fields.bedtime")
                            ) + "\n            "
                          ),
                          _c("v-icon", [_vm._v("bedtime")])
                        ],
                        1
                      )
                    ]),
                    _c("v-flex", { attrs: { shrink: "" } }, [
                      _c("span", { staticClass: "starling-body" }, [
                        _c("span", { staticClass: "dynamic-text" }, [
                          _vm._v(
                            _vm._s(_vm.isDirty ? "--:--" : _vm.formattedBedTime)
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { staticClass: "mb-2", attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { grow: "" } }, [
                      _c(
                        "p",
                        { staticClass: "starling-body font-weight-semi-bold" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("app.tools.sleep_window.fields.waketime")
                            ) + "\n            "
                          ),
                          _c("v-icon", [_vm._v("wb_sunny")])
                        ],
                        1
                      )
                    ]),
                    _c("v-flex", { attrs: { shrink: "" } }, [
                      _c("p", { staticClass: "starling-body" }, [
                        _c("span", { staticClass: "dynamic-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.isDirty ? "--:--" : _vm.formattedWakeTime
                            )
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }