var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "element-mcq-choice",
      attrs: { "d-inline-flex": "", "gap-xs-3": "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-0",
              class: { selected: _vm.therapyChoice.selected },
              attrs: {
                outline: !_vm.therapyChoice.selected,
                round: "",
                large: "",
                icon: ""
              }
            },
            [
              _vm.therapyChoice.selected
                ? _c("v-icon", [_vm._v("mdi-check")])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { grow: "", "align-self-center": "" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-0": "", "ma-0": "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("element-styled-content", {
                        attrs: {
                          content: _vm.content,
                          uen: _vm.uen,
                          removeBottomMargin: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }