import { render, staticRenderFns } from "./element-details.vue?vue&type=template&id=1a11db59&scoped=true&"
import script from "./element-details.vue?vue&type=script&lang=ts&"
export * from "./element-details.vue?vue&type=script&lang=ts&"
import style0 from "./element-details.vue?vue&type=style&index=0&id=1a11db59&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a11db59",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VChip,VDialog,VDivider,VIcon,VSpacer,VSpeedDial,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src233137091/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a11db59')) {
      api.createRecord('1a11db59', component.options)
    } else {
      api.reload('1a11db59', component.options)
    }
    module.hot.accept("./element-details.vue?vue&type=template&id=1a11db59&scoped=true&", function () {
      api.rerender('1a11db59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/element-details.vue"
export default component.exports