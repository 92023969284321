var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-layout",
        { staticClass: "element-sleep-track", attrs: { row: "", wrap: "" } },
        [
          _vm.isHistory
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", lg9: "" } },
                  [
                    _c("sleep-track-list", {
                      attrs: { values: _vm.storeSleepTrack, inline: "" },
                      on: { update: _vm.refreshList }
                    })
                  ],
                  1
                )
              ]
            : [
                !_vm.linkedSleepTrack.id || _vm.editing
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "", lg9: "" } },
                      [
                        _c("sleep-track-form", {
                          attrs: {
                            value: _vm.linkedSleepTrack,
                            cancellable: _vm.editing,
                            inline: ""
                          },
                          on: { update: _vm.onUpdate, cancel: _vm.onCancel }
                        })
                      ],
                      1
                    )
                  : _c(
                      "v-flex",
                      { attrs: { xs12: "", lg9: "" } },
                      [
                        _c("sleep-track-card", {
                          attrs: { sleep: _vm.linkedSleepTrack },
                          on: { edit: _vm.onEdit, update: _vm.onUpdate }
                        })
                      ],
                      1
                    )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }