




import Vue from 'vue';
import SleepWindowInput from '@/views/components/utilities/sleep-window-input.vue';
import store from '@/scripts/store';

export default Vue.extend({
  name: 'sleep-window-input-wrapper',
  components: { SleepWindowInput },
  data() {
    return {
      sleepWindow: null,
      loaded: false,
    };
  },
  watch: {
    sleepWindow(newVal) {
      if (newVal && newVal.computedBedTime) {
        this.$log.debug('New sleep window found, emitting \'completed\' event');
        this.$emit('completed');
      }
    },
  },
  mounted() {
    store.dispatch('sleep/getSleepWindow').then(res => {
      this.sleepWindow = res;
    }).finally(() => {
      this.loaded = true;
    });
  },
  methods: {
    onSleepWindowUpdate() {
      store.dispatch('sleep/setSleepWindow', this.sleepWindow).then(res => {
        this.sleepWindow = res;
      });
    },
  },
});
