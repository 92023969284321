

















































import Vue from 'vue';
import cssVars from 'css-vars-ponyfill';
import { IAssessment, IAssessmentQuestion, IAssessmentQuestionChoice, IUserAssessment, IUserAssessmentAnswer, IUserAssessmentAnswerChoice } from '@/scripts/store/modules/assessments/types';
import { INote } from '@/scripts/store/modules/tools/notes/types';
import { IStyleContent, ITherapyContentAssessment, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import AssessmentQuestion from '@/views/tools/assessments/assessment-question.vue';
import ElementPartInstructional from '@/views/products/components/elements-parts/element-part-instructional.vue';
import InputNoteWrapperAssessment from '@/views/tools/notes/input-note-wrapper-assessment.vue';

export default Vue.extend({
  name: 'element-assessment',
  components: {
    AssessmentQuestion,
    ElementPartInstructional,
    InputNoteWrapperAssessment,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentAssessment,
      required: true,
    },
  },
  data() {
    return {
      firstLoad: true,
      loading: true,
      loadedAssessment: null as IAssessment | null,
      currentStep: 0,
      totalSteps: 0,
      hasMoreSteps: false,
      instructions: { key: StyleTypeEnum.INSTRUCTIONAL, content: this.$t('app.products.element_types.assessment.instructions') } as IStyleContent,
    };
  },
  computed: {
    questions(): Array<IAssessmentQuestion> | undefined {
      return this.loadedAssessment?.questions;
    },
    activeQuestion(): IAssessmentQuestion | null {
      return this.questions ? this.questions[this.currentStep - 1] : null;
    },
    isAssessmentComplete(): boolean {
      return this.questions?.every(question => question.choices.some(choice => choice.selected)) || false;
    },
  },
  mounted() {
    cssVars({});

    this.$store.dispatch('assessment/getAssessment', { name: this.therapyContent.assessmentName, uen: this.therapyContent.uen })
      .then((res: IAssessment) => {
        this.loadedAssessment = res;

        if (res.currentQuestion <= res.totalQuestions) {
          // eslint-disable-next-line vue/no-mutating-props
          this.therapyContent.userAssessmentId = res.userAssessmentId;
          this.totalSteps = res.totalQuestions;
          this.hasMoreSteps = res.hasMoreQuestions;
        } else {
          this.onCompleteAssessment(res);
        }
        this.currentStep = res.currentQuestion;
        this.loading = false;
      }).finally(() => {
        this.firstLoad = false;
        const completed = this.loadedAssessment?.questions.every(question => question.choices.some(choice => choice.selected));
        this.$emit('completeElement', { valid: completed, autoComplete: !completed });
      });
  },
  methods: {
    onClickNext(question: IAssessmentQuestion) {
      if (!this.loadedAssessment || !question) return;

      this.$log.debug('Should send answer to backend');

      const choicesToSave: IUserAssessmentAnswerChoice[] = question.choices.filter(c => c.selected)
        .map(c => {
          return { id: c.id, value: c.value };
        });

      if (!choicesToSave || !choicesToSave.length) {
        this.$log.debug('No choices were selected/found', question.choices);
      }

      const questionToSave: IUserAssessmentAnswer = {
        id: question.id,
        choices: choicesToSave,
      };

      const otherQuestions = this.loadedAssessment.questions.filter(q => q.id !== question.id && !!q.choices.some(c => c.selected)).map(q => {
        return {
          id: q.id,
          choices: q.choices.filter(c => c.selected).map(c => {
            return { id: c.id, value: c.value };
          }),
        };
      });

      const toSave: IUserAssessment = {
        userAssessmentId: this.loadedAssessment.userAssessmentId,
        uen: this.therapyContent.uen,
        assessmentId: this.loadedAssessment.id,
        questions: [ ...otherQuestions, questionToSave ],
        notes: this.loadedAssessment.notes,
      };

      this.saveAssessment(toSave);
    },
    onNotesChanged(notes: INote) {
      if (!this.loadedAssessment) return;
      this.onSaveNote(notes);

      const toSave: IUserAssessment = {
        userAssessmentId: this.loadedAssessment.userAssessmentId,
        uen: this.therapyContent.uen,
        assessmentId: this.loadedAssessment.id,
        questions: [],
        notes,
      };

      this.saveAssessment(toSave);
    },
    saveAssessment(toSave: IUserAssessment) {
      this.$log.debug('Submitting assessment', toSave);

      this.loading = true;
      this.$store.dispatch('assessment/saveAssessment', toSave)
        .then((res: IAssessment) => {
          if (res.questions.every(question => question.choices.some(choice => choice.selected))) {
            this.onCompleteAssessment(res);
          } else {
            this.onChangeQuestion(res);
          }
          this.loading = false;
        });
    },
    onChangeQuestion(assessment: IAssessment) {
      this.loadedAssessment = assessment;
      this.totalSteps = assessment.totalQuestions;
      this.hasMoreSteps = assessment.hasMoreQuestions;
      this.$emit('completeElement', { valid: false });
    },
    onSelectChoice(question: IAssessmentQuestion, selection: { choices: Array<IAssessmentQuestionChoice>, oldSelection: boolean }) {
      this.$log.debug('Selected choices', selection);
      question.choices.forEach(c => {
        c.selected = selection.choices.some(sc => sc.id === c.id);
      });
      if (selection.oldSelection) {
        if (this.currentStep === this.totalSteps && !this.hasMoreSteps) {
          this.$emit('completeElement', { valid: true });
        }
      } else {
        this.onClickNext(question);
      }
    },
    onCompleteAssessment(assessment: IAssessment) {
      // Assessment completed
      this.loadedAssessment = assessment;
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.userAssessmentId = assessment.userAssessmentId;
      this.totalSteps = assessment.totalQuestions;
      this.hasMoreSteps = false;
      this.$log.debug('Created user assessment');
      this.$emit('completeElement', { valid: true });
    },
    onSaveNote(updatedNote: INote) {
      this.loading = true;
      const state = {
        ...updatedNote,
        date: new Date().toISOString(),
        uen: this.therapyContent.uen,
      };
      try {
        this.$store.dispatch('notes/saveNote', state).then(res => {
          this.$emit('completeElement', { valid: true });
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },
});
