



















import Vue from 'vue';
import SleepTrackCard from '@/views/tools/sleep/components/sleep-track-card.vue';
import SleepTrackForm from '@/views/tools/sleep/components/track/sleep-track-form.vue';
import SleepTrackList from '@/views/tools/sleep/components/sleep-track-list.vue';
import { ITherapyContentSleepTrack, StylePeriodEnum, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';

export default Vue.extend({
  name: 'element-sleep-track',
  components: {
    SleepTrackCard,
    SleepTrackForm,
    SleepTrackList,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentSleepTrack,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      linkedSleepTrack: {} as ISleepTracker,
      editing: false,
    };
  },
  computed: {
    isHistory(): boolean {
      return this.therapyContent.styleContent?.find(part => part.key === StyleTypeEnum.CONTENT)?.period === StylePeriodEnum.HISTORY;
    },
    storeSleepTrack(): ISleepTracker[] {
      return this.$store.getters['sleep/sleepTrackList'];
    },
  },
  mounted() {
    this.$log.debug('SleepTrack element', this.therapyContent);
    this.$emit('completeElement', { valid: false });

    if (this.isHistory) {
      this.refreshList();
    } else {
      this.getSleepTrack();
    }
  },
  methods: {
    refreshList() {
      this.loading = true;
      this.$store.dispatch('sleep/getSleepTrackList').then(() => {
        this.$emit('completeElement', { valid: true });
      }).finally(() => {
        this.loading = false;
      });
    },
    getSleepTrack() {
      this.loading = true;

      if (!this.therapyContent.userSleepTrackId) {
        this.resetConfig();
        this.loading = false;
        return;
      }

      this.$store.dispatch('sleep/getSleepTrack', this.therapyContent.userSleepTrackId).then(res => {
        if (!res || res.status >= 300) throw new Error();
        this.linkedSleepTrack = res;
        this.resetConfig();
        this.$emit('completeElement', { valid: true });
      }).catch(() => {
        this.resetConfig();
      }).finally(
        () => {
          this.loading = false;
        },
      );
    },
    resetConfig() {
      if (!this.linkedSleepTrack.uen) {
        this.linkedSleepTrack.uen = this.therapyContent.uen;
      }
    },
    onEdit() {
      this.editing = true;
      this.$emit('completeElement', { valid: false });
    },
    onCancel() {
      this.editing = false;
    },
    onUpdate(sleeptrack: ISleepTracker) {
      this.linkedSleepTrack = sleeptrack || {};
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.userSleepTrackId = sleeptrack?.id ? parseInt(sleeptrack.id) : undefined;
      this.resetConfig();
      this.editing = false;
      this.$emit('completeElement', { valid: !!sleeptrack });
    },
  },
});
